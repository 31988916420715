import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Button, Row, Badge, Modal, Accordion } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB, DATE_FORMAT_WEB } from 'Constants/dateFormatConstants';
import RestClient from 'Services/RestClientService';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { useDispatch } from 'react-redux';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const JobAssignmentCard = (props) => {
  console.log(props.assignment);
  const dispatch = useDispatch();
  const { assignment, employee, setRefreshParentComponent, refreshParentComponent } = props;

  const keyWorkdsOptionsList = [
    { label: 'Accepted', value: 'Accepted' },
    { label: 'Decline', value: 'Decline' },
  ];
  const [showEditAssignmentModal, setShowEditAssignemntModal] = useState(false);
  const [desiredAssignment, setDesiredAssignment] = useState({
    positionAssignId: assignment.positionAssignId,
    mtpAssigmentStatus: assignment.mtpAssigmentStatus,
    isAssignmentChanged: false,
    mtpAssignmentComment: '',
  });
  const [desiredAssignmentError, setDesiredAssignmentError] = useState({
    mtpAssignmentComment: '',
    mtpAssigmentStatus: '',
  });

  const [mtpHistoryList, setMtpHistoryList] = useState([
    {
      positionAssignId: 0,
      status: '',
      startDate: '',
      endDate: '',
      comment: '',
      timestamp: '',
      updtedBy: '',
    },
  ]);

  const validateEditAssignmentForm = () => {
    if (!desiredAssignment.mtpAssigmentStatus) {
      setDesiredAssignmentError({ mtpAssigmentStatus: 'Status is required' });
      return false;
    }

    if (!desiredAssignment.mtpAssignmentComment && desiredAssignment.mtpAssigmentStatus === 'Decline') {
      setDesiredAssignmentError({ mtpAssignmentComment: 'Please let us know why you are declining the assignment' });
      return false;
    }

    return true;
  };

  const handleEditAssignment = () => {
    const { mtpAssigmentStatus, mtpAssigmentComment } = desiredAssignment;
    const { staffID } = employee;
    const { mplid, positionAssignId } = assignment;

    console.log('handleEditAssignment', desiredAssignment);
    console.log('handleEditAssignment', assignment);

    if (!validateEditAssignmentForm()) {
      return;
    }
    setShowEditAssignemntModal(false);

    const payload = {
      mplId: mplid,
      positionAssignId: positionAssignId,
      staffId: staffID,
      mtpAssigmentStatus,
      mtpAssigmentComment,
      assigmentStatus: mtpAssigmentStatus,
      assigmentStartDate: assignment.startDate,
      assigmentEndDate: assignment.endDate,
      assigmentTypeOfFlight: 'Start of Season',
      assigmentStaffPositionType: assignment.assignmentStaffPositionType,
    };

    dispatch(setLoader(true));
    RestClient.Post(`positionAssign/update-assignment`, payload)
      .then((result) => {
        toastr.success('Success', result.message);
      })
      .finally(() => {
        setRefreshParentComponent(Math.random());
        setDesiredAssignmentError({});
        dispatch(setLoader(false));
      });
  };

  const setStatusColor = (status) => {
    switch (status) {
      case 'Accept':
      case '2nd Offer Accepted':
        return 'success';
      case 'Ready To Communicate Summer':
      case 'Ready To Communicate Winter':
        return 'warning';
      case 'Declined':
      case 'Decline':
        return 'danger';
      default:
        return 'primary';
    }
  };

  useEffect(() => {
    // console.log(assignment);
  }, [refreshParentComponent]);

  useEffect(() => {
    RestClient.Get(`history/mtpHistoryByPositionAssignId/${assignment.positionAssignId}`).then((serverResponse) => {
      // console.log('serverResponse', serverResponse);
      if (!serverResponse) return;
      setMtpHistoryList(serverResponse.mtpHistoryList);
    });
  }, [refreshParentComponent]);

  return (
    <>
      <Card className="staff-profile-card">
        <Card.Header className="d-flex justify-content-between">
          <div>
            <i className="fa fa-globe mr-2"></i>
            <i className="fa fa-location-arrow mr-2"></i>
            Position propoused for : {employee.firstName} {employee.lastName}
          </div>
          <div>
            <Badge variant={setStatusColor(assignment.mtpAssigmentStatus)} pill style={{ fontSize: '0.8rem' }}>
              Mtp status : {assignment.mtpAssigmentStatus}
            </Badge>
          </div>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={3}>
                <Form.Label>Position assign id</Form.Label>
                <Form.Control type="text" value={assignment.positionAssignId} disabled />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={3}>
                <Form.Label>Mplid</Form.Label>
                <Form.Control type="text" value={assignment.mplid} disabled />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={3}>
                <Form.Label>Season</Form.Label>
                <Form.Control type="text" value={assignment.season} disabled />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={3}>
                <Form.Label>Concept Hotel</Form.Label>
                <Form.Control type="text" value={assignment.conceptHotel} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Region</Form.Label>
                <Form.Control type="text" value={`${assignment.region}`} disabled />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Destination</Form.Label>
                <Form.Control type="text" value={assignment.destination} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Job family</Form.Label>
                <Form.Control type="text" value={assignment.jobFamily} disabled />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Job title </Form.Label>
                <Form.Control type="text" value={assignment.jobTitle} disabled />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Assignment start date</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                  value={DateTimeFormater(assignment.startDate, null, DATE_FORMAT_WEB)}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} sm={6} md={6}>
                <Form.Label>Assignment end date</Form.Label>
                <Datetime
                  timeFormat={false}
                  dateFormat={DATE_FORMAT_WEB}
                  inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                  value={DateTimeFormater(assignment.endDate, null, DATE_FORMAT_WEB)}
                />
              </Form.Group>
            </Form.Row>
          </Form>

          <hr></hr>

          <Accordion hidden={true}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Badge} pill variant="primary" eventKey="0">
                  See history
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Created by</th>
                        <th scope="col">Creation date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mtpHistoryList.map((history, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Badge variant={setStatusColor(history.status)} pill style={{ fontSize: '0.8rem' }}>
                                {history.status}
                              </Badge>
                            </td>
                            <td>{history.comment}</td>
                            <td>{history.updatedBy}</td>
                            <td>{DateTimeFormater(history.createdDate, null, DATETIME_FORMAT_WEB)}</td>
                          </tr>
                        );
                      })}
                      {mtpHistoryList.length === 0 && (
                        <tr>
                          <td colSpan="4" align="center">
                            No results found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Card.Body>
        <Card.Footer>
          <Row className="d-flex my-2">
            <Button onClick={() => setShowEditAssignemntModal(true)} variant="info" size="lg" className="mx-2">
              Accept / Decline
            </Button>
          </Row>
        </Card.Footer>
      </Card>

      <Modal
        size="lg"
        show={showEditAssignmentModal}
        onHide={() => {
          setShowEditAssignemntModal(false);
        }}
        onExit={() => {
          setDesiredAssignment({
            id: assignment.positionAssignId,
            isAssignmentChanged: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>[{assignment.positionAssignId}] - Edit propoused assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Current Mtp Status : {assignment.mtpAssigmentStatus}</Form.Label>
                <Select
                  options={keyWorkdsOptionsList}
                  closeMenuOnSelect
                  isClearable
                  onChange={(e) => {
                    setDesiredAssignmentError({});
                    setDesiredAssignment({
                      ...desiredAssignment,
                      isAssignmentChanged: true,
                      mtpAssigmentStatus: e?.value,
                    });
                  }}
                  value={keyWorkdsOptionsList.filter((option) => {
                    return option.value === desiredAssignment.mtpAssigmentStatus;
                  })}
                />
                <Form.Text className="form-text-red">{desiredAssignmentError.mtpAssigmentStatus}</Form.Text>
              </Form.Group>
            </Form.Row>
            <div hidden={desiredAssignment.mtpAssigmentStatus != 'Decline'}>
              <hr></hr>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Other comments </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={assignment.comments}
                    onChange={(e) => {
                      setDesiredAssignmentError({});
                      setDesiredAssignment({
                        ...desiredAssignment,
                        isAssignmentChanged: true,
                        mtpAssignmentComment: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Text className="form-text-red">{desiredAssignmentError.mtpAssignmentComment}</Form.Text>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditAssignemntModal(false)}>
            Cancel
          </Button>
          <Button
            variant="info"
            onClick={() => handleEditAssignment(assignment.positionAssignId)}
            disabled={!desiredAssignment.isAssignmentChanged}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobAssignmentCard;
